<template>
    <div>
        <div class="row w-full">
            <div class="col-md-4">
                <span class="span-placeholder">Valor del vehículo</span>
                <div class="inputwrapper" data-required="MXM">
                    <input 
                        id="demoTextBox"  
                        class="inputDataText" 
                        placeholder="$X,XXX,XXX.XX" 
                        v-validate="'max:11'" 
                        v-model="valores.valor" 
                        name="vv" 
                        @change="updateValores(); formatea(); calculaPorcentajeCredito()" v-on:keyup="validavalor()" v-on:keypress="isNumber($event)"/>
                    <span class="text-danger span-placeholder" v-show="errors.has('vv')">Mínimo son 6 digitos</span>
                </div>
            </div>
            
            <div class="col-md-4">
                <span class="span-placeholder">Monto de enganche</span>
                <div class="alinear" >
                    <input 
                        id="demoTextBox" 
                        class="inputDataText" 
                        placeholder="$X,XXX,XXX.XX" 
                        v-validate="'max:13'" 
                        label="Monto de crédito" 
                        v-model="valores.credito" 
                        name="credito" 
                        @change="updateValores(); formateaCredito(); calculaPorcentajeCredito()" v-on:keyup="validaCredito()"
                        v-on:keypress="isNumber($event)"/>
                    <div class="porcentaje">{{valores.porcentajeCredito}}</div>    
                </div>
                <span class="text-danger span-placeholder" v-show="validacion">Debe ser máximo el {{ porcentajeMaximo }}% del valor de la propiedad. </span>
            </div>
        </div>
        
        <div class="row w-full">
            <div class="col-md-4">
                <span class="span-placeholder">Año</span>
                    <!-- @input="updateValores(), buscaBancos()" -->
                <select 
                    @input="updateValores()"
                    v-model="valores.aniosACotizar" 
                    class="vs-input text-base">
                    <option :value="tipo.value" v-for="tipo in anios" :key="tipo.value">{{ tipo.value }}</option>
                </select>
            </div>

            <div class="col-md-4">
                <span class="span-placeholder">Plazos a cotizar</span>
                <!-- @input="updateValores(), buscaBancos()" -->
                <v-select 
                    @input="updateValores()"
                    v-model="valores.plazosACotizar" 
                    multiple 
                    :closeOnSelect="false" 
                    :options="plazos" :dir="$vs.rtl ? 'rtl' : 'ltr'" />
            </div>
        </div>
        <!-- <input class="vs-input" hidden v-model="otro" name="otro" @change="updateValores()" /> -->

    </div>
</template>

<script>
import vSelect from 'vue-select';
export default {
    components: {
        vSelect,
    },
    props: {
        otro: {
            type: Number,
            required: false
        },
        porcentajeMaximo: {
            type: Number,
            required: false
        }
    },
    data() {
        return {
            plazos: [              
                { label: '6 meses',  value: '6' },
                { label: '12 meses',  value: '12' },
                { label: '24 meses',  value: '24' },
                { label: '36 meses',  value: '36' },
                { label: '48 meses',  value: '48' },
                { label: '60 meses',  value: '60' },
                { label: '72 meses',  value: '72' },
                { label: '84 meses',  value: '84' },
                { label: '96 meses',  value: '96' },
                { label: '108 meses',  value: '108' },
                { label: '120 meses',  value: '120' },
            ],
            anios: [
                { label: '2024',  value: '2024' },
                { label: '2023',  value: '2023' },
                { label: '2022',  value: '2022' },
                { label: '2021',  value: '2021' },
                { label: '2020',  value: '2020' },
                { label: '2019',  value: '2019' },
                { label: '2018',  value: '2018' },
                { label: '2017',  value: '2017' },
                { label: '2016',  value: '2016' },
                { label: '2015',  value: '2015' },
            ],
            key: '',
            id: '',
            valores: {
                destino: 41,
                valor: '1,000,000',
                credito: '700,000',
                aniosACotizar: '2024',
                // no se usa, evita que en Credito.vue no de errores el metodo update() y cambiaBancos
                esquemasACotizar:[{ label: 'Pago Fijo',  value: '2' }],
                // no se usa
                gastosNotarial:6,
                plazosACotizar: [{ label: '120 meses',  value: '120' }],
                bancos: [],
                otro: 0,
                porcentajeCredito: 70.00
            },
        }
    },
    mounted() {
        this.id = this.$route.params.id
        this.getApikey();
        if (this.id) {
            this.dameCasoAuto();
        }
        if (this.$route.path == '/nuevaCotizacion' && localStorage.getItem('caso')) {
            this.id = localStorage.getItem('caso');
            this.dameCasoAuto();
        }
         this.updateValores();
    },
    watch: {
        // otro: function() {
        //     if (this.otro > 0) {
        //         this.updateValores();
        //     }
        // },
        // 'valores.plazosACotizar': function(newValue, oldValue) {
            // console.log( 'plazosAutomotriz', newValue );
            // this.updateValores();
        // },
        'valores.aniosACotizar': function(newValue, oldValue) {
            this.updateValores();
        }
    },
    computed: {
        validacion() {
            return this.valores.credito.toString().replace(/,/g,"") > this.valores.valor.toString().replace(/,/g,"") * 0.95;
        },
        // valor() {
        //     return this.valores.valor
        // },
        // credito() {
        //     return this.valores.credito
        // }
    },
    methods: {
        getApikey() {
            if(!JSON.parse(localStorage.getItem('userInfo')).ApiKey) {
                this.$router.push('/login');
            } else {
                this.key = JSON.parse(localStorage.getItem('userInfo')).ApiKey;
            }
        },
        updateValores() {
            this.valores.otro = this.otro;
            this.$emit('updateValores',this.valores);
            // console.log( 'emite auto.vue', this.valores );
        },
        // buscaBancos() { 
        //     this.valores.otro = this.otro;
        //     this.$emit('buscaBancos', this.valores);

        //     console.log( 'busca auto.VUE', this.valores );
        // },
        dameCaso() {
            let self = this;
            let objRequestDameCaso = {
                strApiKey: this.key,
                strMetodo: 'DameCaso',
                objCaso: {
                    Id: this.id
                }
            }
            
            this.$axios.post(
                '/', 
                objRequestDameCaso, 
                {headers : {'Content-Type':'application/x-www-form-urlencoded; charset=UTF-8'}})
                .then(response => {
                    if (response.data.intEstatus == 0) {
                        let cotizacion = response.data.objContenido;
                        this.valores.destino = cotizacion.Destino;

                        this.updateValores(); // estaba en el mounted
                    } else {
                        this.$vs.notify({
                            title: 'Ocurrio un error en DameCaso',
                            text: response.data.strError,
                            color: 'danger',
                            position:'top-right'
                        })
                    }
                }).catch(function (error) {
                    self.$vs.notify({
                        title:'Ocurrio un error de sistema',
                        text: error,
                        color:'danger',
                        position:'top-right'
                    })
                })
        },
        dameCasoAuto() {
            let self = this;
            let objRequestDameCaso = {
                strApiKey: this.key,
                strMetodo: 'DameCasoAuto',
                objCaso: {
                    Id: this.id
                }
            }
            
            this.$axios.post('/', objRequestDameCaso, { headers : {'Content-Type':'application/x-www-form-urlencoded; charset=UTF-8'}})
            .then( response => {
                if(response.data.intEstatus == 0) {
                    let cotizacion = response.data.objContenido;

                    this.valores.plazosACotizar = [];
                    this.valores.valor = (cotizacion.Costo).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                    this.valores.credito = (cotizacion.Enganche).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                    this.valores.aniosACotizar = cotizacion.Anio;
                    
                    if (cotizacion.Plazo) {

                        let vctPlazos = cotizacion.Plazo.split(',');
                        for (var i = 0; i < vctPlazos.length; i++) {
                            this.valores.plazosACotizar.push({
                                label: vctPlazos[i] + ' meses',
                                value: vctPlazos[i]
                            })
                        }
                    }

                    this.dameCaso(); // para obtener el destino
                    this.calculaPorcentajeCredito();
                } else {
                    this.$vs.notify({
                        title: 'Ocurrio un error en DameCasoAuto',
                        text: response.data.strError,
                        color: 'danger',
                        position: 'top-right'
                    })
                }
            }).catch(function (error) {
                self.$vs.notify({
                    title: 'Ocurrio un error de sistema',
                    text: error,
                    color: 'danger',
                    position: 'top-right'
                })
            })
        },
        calculaPorcentajeCredito() {
            this.valores.porcentajeCredito = (this.valores.credito.toString().replace(/,/g,"") / this.valores.valor.toString().replace(/,/g,"")) * 100;
            this.valores.porcentajeCredito = this.valores.porcentajeCredito.toFixed(2) + '%';
        },
        validavalor() {
            this.valores.valor = this.valores.valor.replace(/,/g,"");
            const regex = /^(\d{1,9})(\.\d{0,2})?$/;  
            const matches = regex.test(this.valores.valor);
            if (matches===true) {
                this.valores.valor = this.valores.valor;
            } else {
                this.valores.valor = this.valores.valor.replace(/.$/, '');
            }
        },
        validaCredito() {
            this.valores.credito=this.valores.credito.replace(/,/g,"")
            const regex = /^(\d{1,8})(\.\d{0,2})?$/; 
            const matches = regex.test(this.valores.credito);
            if (matches===true) {
                this.valores.credito= this.valores.credito;
            } else {
                this.valores.credito=this.valores.credito.replace(/.$/, '');
            }
        },
        formatea() {
            this.valores.valor = this.valores.valor.toString().match(/^-?\d+(?:\.\d{0,2})?/)[0];
            if (this.valores.valor=='' || this.valores.valor==0) {
                this.valores.valor = 100000;
            }
            if (this.valores.valor.length < 6 || this.valores.valor.length > 13) {
                this.valores.valor = 100000; 
            }
            const exp = /(\d)(?=(\d{3})+(?!\d))/g;  
            const rep = '$1,';
            this.valores.valor= this.valores.valor.toString().replace(exp,rep);
        },
        formateaCredito() {
            this.valores.credito = this.valores.credito.toString().match(/^-?\d+(?:\.\d{0,2})?/)[0];
            if (this.valores.credito == '' || this.valores.credito == 0 || this.valores.credito.length > 13) {
                this.valores.credito = 700000;
            }
            const exp = /(\d)(?=(\d{3})+(?!\d))/g;  
            const rep = '$1,';
            this.valores.credito= this.valores.credito.toString().replace(exp,rep);
        },
        isNumber: function(evt) {
            evt = (evt) ? evt : window.event;
            let charCode = (evt.which) ? evt.which : evt.keyCode;
            if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
                evt.preventDefault();
            } else {
                return true;
            }
        },
    }
}
</script>