<template>
	<div class="">
		<div class="row w-full p-4">
			<div class="title_pre">Bancos</div>
			<div class="col-12">
				<vs-tooltip text="Scotiabank" position="bottom" v-if="this.solicidadoBancosSolicitudesScotia == 'MuestraForm'">
					<a @click="AbrirPanel()">
						<vs-button type="flat">
							<img :src="'https://cotizador.socasesores.com/app/img/LogosSolicitudes/SolicitudScotiabank.PNG'" />
						</vs-button>
					</a>
					<span class="ml-4">{{ nameProducto }}</span>
				</vs-tooltip>
			</div>
		</div>

		<div class="title_pre" v-if="this.solicidadoBancosSolicitudesScotia == true">No hay productos bancarios con servicio de precalificacion seleccionados</div>

		<br /><br />

		<div class="tab-content" id="myTabContent" v-if="this.opcionElejida == true">
			<div v-if="this.externaBrokerSolicitante"><h5 class="text-danger">Ya precalificaste previamente a este cliente favor de validar</h5></div>

			<div v-if="this.externaSolicitante">
				<h5 class="text-danger">Aviso 236, este cliente ya fue capturado por otro broker en la fecha {{ this.fechaRegistroExterna }}, favor de validar.</h5>
			</div>

			<div class="title_pre">Campos</div>
			<div class="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
				<div class="">
					<div class="accordion" id="accordionExample">
						<div class="accordion-item">
							<h2 class="accordion-header" id="headingOne">
								<button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne" v-bind:style="[PersonalesCompleto ? { backgroundColor: '#BFF8BF !important' } : { backgroundColor: '#D1F1E9   !important' }]">Solicitante</button>
							</h2>
							<div id="collapseOne" class="accordion-collapse collapse" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
								<div class="accordion-body">
									<PersonalesGeneral :varianteId="varianteId" v-on:bandera="update($event)" />
								</div>
							</div>
						</div>

						<div class="accordion-item">
							<h2 class="accordion-header" id="headingThree">
								<button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree" v-bind:style="[ReferenciasCompleto ? { backgroundColor: '#BFF8BF !important' } : { backgroundColor: '#D1F1E9   !important' }]">Referencias</button>
							</h2>
							<div id="collapseThree" class="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
								<div class="accordion-body">
									<Referencias v-on:bandera="update($event)" />
								</div>
							</div>
						</div>
						<div class="accordion-item">
							<h2 class="accordion-header" id="headingFour">
								<button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour" v-bind:style="[CoacreditadoCompleto ? { backgroundColor: '#BFF8BF !important' } : { backgroundColor: '#D1F1E9   !important' }]">Coacreditado</button>
							</h2>
							<div id="collapseFour" class="accordion-collapse collapse" aria-labelledby="headingFour" data-bs-parent="#accordionExample">
								<div class="accordion-body">
									<ObligadoSolidario v-on:bandera="update($event)" />
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="tab-pane fade" id="profile" role="tabpanel" aria-labelledby="profile-tab">
				<div class="tab-body">
					<div class="accordion" id="accordionExample2">
						<div class="accordion-item">
							<h2 class="accordion-header" id="headingOne-pro">
								<button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne-pro" aria-expanded="true" aria-controls="collapseOne-pro">Ingresos</button>
							</h2>
							<div id="collapseOne-pro" class="accordion-collapse collapse" aria-labelledby="headingOne-pro" data-bs-parent="#accordionExample2">
								<div class="accordion-body">
									<Ingresos />
								</div>
							</div>
						</div>
						<div class="accordion-item">
							<h2 class="accordion-header" id="headingTwo-pro">
								<button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo-pro" aria-expanded="false" aria-controls="collapseTwo-pro">Cuentas</button>
							</h2>
							<div id="collapseTwo-pro" class="accordion-collapse collapse" aria-labelledby="headingTwo-pro" data-bs-parent="#accordionExample2">
								<div class="accordion-body">
									<CuentasBancarias />
								</div>
							</div>
						</div>
						<div class="accordion-item">
							<h2 class="accordion-header" id="headingThree-pro">
								<button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree-pro" aria-expanded="false" aria-controls="collapseThree-pro">Adeudos</button>
							</h2>
							<div id="collapseThree-pro" class="accordion-collapse collapse" aria-labelledby="headingThree-pro" data-bs-parent="#accordionExample2">
								<div class="accordion-body">
									<Adeudos />
								</div>
							</div>
						</div>
						<div class="accordion-item">
							<h2 class="accordion-header" id="headingFour-pro">
								<button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour-pro" aria-expanded="false" aria-controls="collapseFour-pro">Beneficiarios</button>
							</h2>
							<div id="collapseFour-pro" class="accordion-collapse collapse" aria-labelledby="headingFour-pro" data-bs-parent="#accordionExample2">
								<div class="accordion-body">
									<Beneficiarios />
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="tab-pane fade" id="contact" role="tabpanel" aria-labelledby="contact-tab">
				<div class="tab-body">
					<Aseguradoras />
				</div>
			</div>
			<div class="tab-pane fade" id="datos" role="tabpanel" aria-labelledby="datos-tab">
				<div class="tab-body">
					<DatosAdicionales />
				</div>
			</div>
		</div>
		<div class="row w-full">
			<div class="col-md-5"></div>
			<!--  <div class="col-md-3">
      <vs-button class="btn btn-primary">Guardar Progreso</vs-button>
    </div>
    <div class="col-md-4">
      <vs-button class="btn btn-blue">Mandar precalificación</vs-button>
    </div>-->
		</div>
	</div>

	<!--<vs-row>
    <vs-col vs-type="flex" vs-justify="top" vs-align="top" vs-lg="2" vs-sm="12" vs-xs="12">
      <vs-collapse>

    <vs-collapse-item>
      <div slot="header">
        Generales
      </div>
      <vs-button color="primary" type="line" line-origin="left" class="w-full" @click="changeTab(2)">Reporte de Crédito y Precalificación</vs-button>
      <vs-button color="primary" type="line" line-origin="left" class="w-full" @click="changeTab(7)">Dependientes económicos</vs-button>
      <vs-button color="primary" type="line" line-origin="left" class="w-full" @click="changeTab(8)">Referencias</vs-button>
      <vs-button color="primary" type="line" line-origin="left" class="w-full" @click="changeTab(12)">Obligado Solidario</vs-button>
    </vs-collapse-item>

    <vs-collapse-item>
      <div slot="header">
        Ingresos
      </div>
      <vs-button color="primary" type="line" line-origin="left" class="w-full" @click="changeTab(3)">Ingresos</vs-button>
      <vs-button color="primary" type="line" line-origin="left" class="w-full" @click="changeTab(6)">Cuentas Bancarias</vs-button>
      <vs-button color="primary" type="line" line-origin="left" class="w-full" @click="changeTab(4)">Adeudos</vs-button>
      <vs-button color="primary" type="line" line-origin="left" class="w-full" @click="changeTab(5)">Beneficiarios</vs-button>
    </vs-collapse-item>

    <vs-collapse-item>
      <div slot="header">
        Seguros
      </div>
      <vs-button color="primary" type="line" line-origin="left" class="w-full" @click="changeTab(9)">Datos de aseguradoras</vs-button>
    </vs-collapse-item>

    <vs-collapse-item>
      <div slot="header">
        Datos adicionales
      </div>
      <vs-button color="primary" type="line" line-origin="left" class="w-full" @click="changeTab(10)">Datos adicionales</vs-button>
      <vs-button color="primary" type="line" line-origin="left" class="w-full" @click="changeTab(11)">Documentos digitalizados</vs-button>
    </vs-collapse-item>

  </vs-collapse>
    </vs-col>

  </vs-row>-->
</template>

<script>
import PersonalesGeneral from '@/views/Caso/PrecalificacionesBancarias/Scotiabank/ScotiabankSolicitante.vue'
import Adeudos from '@/views/Caso/Personales/Adeudos.vue'
import Beneficiarios from '@/views/Caso/Personales/Beneficiarios.vue'
import CuentasBancarias from '@/views/Caso/Personales/CuentasBancarias.vue'
import Dependientes from '@/views/Caso/Personales/Dependientes.vue'
import Referencias from '@/views/Caso/PrecalificacionesBancarias/Scotiabank/Referencias.vue'
import Aseguradoras from '@/views/Caso/Personales/Aseguradoras.vue'
import ObligadoSolidario from '@/views/Caso/PrecalificacionesBancarias/Scotiabank/ScotiabankCoacreditado.vue'
import DocumentosDigitales from '@/views/Caso/Personales/DocumentosDigitales.vue'
import Ingresos from '@/views/Caso/Personales/Ingresos.vue'
import DatosAdicionales from '@/views/Caso/Personales/DatosAdicionales.vue'
import ReporteCredito from '@/views/Caso/Personales/ReporteCredito.vue'

export default {
	components: {
		PersonalesGeneral,
		Adeudos,
		Beneficiarios,
		CuentasBancarias,
		Dependientes,
		Referencias,
		Aseguradoras,
		ObligadoSolidario,
		DocumentosDigitales,
		Ingresos,
		DatosAdicionales,
		ReporteCredito,
	},
	data() {
		return {
			tab: 1,
			id: '',
			key: '',
			email: '',
			solicidadoBancosSolicitudesScotia: 'false',
			opcionElejida: false,
			emailEjecutivo: '',
			emailSolicitante: '',
			externaBrokerSolicitante: false,
			externaSolicitante: false,
			fechaRegistroExterna: '',
			banderaStatus: '',
			emailCasoNuevo2: { bandera2: '' },
			PersonalesCompleto: false,
			ReferenciasCompleto: false,
			CoacreditadoCompleto: false,
			varianteId: '',
			nameProducto: ''
		}
	},
	computed: {
		isSmallerScreen() {
			return window.screen.width < 768
		},
	},
	mounted() {
		this.id = this.$route.params.id
		this.key = JSON.parse(localStorage.getItem('userInfo')).ApiKey
		this.dameCaso()
		// this.DameExternaBrokerSolicitante()
	},
	methods: {
		update(val) {
			this.banderaStatus = val
			//alert('prueba'+this.banderaStatus)

			if (this.banderaStatus == 'Personales') {
				this.emailCasoNuevo2.bandera2 = this.banderaStatus
				this.$emit('bandera', this.emailCasoNuevo2.bandera2)
			} else if (this.banderaStatus == 'CompletoPersonales') {
				this.PersonalesCompleto = this.banderaStatus
			} else if (this.banderaStatus == 'ReferenciasCompleto') {
				this.ReferenciasCompleto = this.banderaStatus
			} else if (this.banderaStatus == 'ReferenciasIncompletas') {
				this.ReferenciasCompleto = false
			} else if (this.banderaStatus == 'CoacreditadoCompleto') {
				this.CoacreditadoCompleto = this.banderaStatus
			} else if (this.banderaStatus == 'CoacreditadoIncompletas') {
				this.CoacreditadoCompleto = false
			} else if (this.banderaStatus == 'IncompletosPersonales') {
				this.PersonalesCompleto = false
			}
		},
		changeTab(id) {
			this.tab = id
		},
		AbrirPanel() {
			this.opcionElejida = true
			//alert('abrir panel')
		},
		isDestinoPermitido(solicitudes) {

			const permitidos = [
				{ id: 19, name: 'SCOTIABANK ADQUISICIÓN DE VIVIENDA VALORA'},
				{ id: 20, name: 'SCOTIABANK ADQUISICIÓN DE VIVIENDA PAGOS OPORTUNOS'},
				{ id: 21, name: 'SCOTIABANK MEJORA DE HIPOTECA VALORA'},
				{ id: 22, name: 'SCOTIABANK MEJORA DE HIPOTECA PAGOS OPORTUNOS'},
				{ id: 25, name: 'SCOTIABANK ADQUISICIÓN DE VIVIENDA HIPOTECA 7X5'},
				{ id: 26, name: 'SCOTIABANK MEJORA DE HIPOTECA HIPOTECA 7X5'},
				{ id: 79, name: 'SCOTIABANK ADQUISICIÓN DE VIVIENDA DESARROLLADORES PAGOS OPORTUNOS'},
				{ id: 94, name: 'SCOTIABANK PREVENTA LIBRE PAGOS OPORTUNOS'},
				{ id: 108, name: 'SCOTIABANK PREVENTA HIPOTECA 7X5'},
				{ id: 110, name: 'SCOTIABANK ADQUISICIÓN DE VIVIENDA DESARROLLADORES VALORA'},
				{ id: 163, name: 'SCOTIABANK ADQUISICIÓN DE VIVIENDA FOVISSSTE PARA TODOS'},
				{ id: 145, name: 'SCOTIABANK PREVENTA LIBRE VALORA'},
				
				//Mejora Precalificacion scotiabank 2 
				//Contruccion, 
				{ id: 99, name: 'SCOTIABANK COMPRA MÁS RENOVACIÓN PAGOS OPORTUNOS'},
				{ id: 100, name: 'SCOTIABANK COMPRA MÁS RENOVACIÓN VALORA'},
				{ id: 104, name: 'SCOTIABANK CONSTRUCCION PAGOS OPORTUNOS'},
				{ id: 106, name: 'SCOTIABANK ADQUISICION DE TERRENO MAS CONSTRUCCION PAGOS OPORTUNOS'},
				{ id: 105, name: 'SCOTIABANK CONSTRUCCION VALORA'},
				{ id: 107, name: 'SCOTIABANK ADQUISICION DE TERRENO MAS CONSTRUCCION VALORA'},
				{ id: 102, name: 'SCOTIABANK MEJORA MÁS RENOVACIÓN PAGOS OPORTUNOS'},
				{ id: 103, name: 'SCOTIABANK MEJORA MÁS RENOVACIÓN VALORA'},
				{ id: 97, name: 'SCOTIABANK RENOVACION PAGOS OPORTUNOS'},
				{ id: 98, name: 'SCOTIABANK RENOVACION VALORA'},
				
				//Liquidez, 
				{ id: 23, name: 'SCOTIABANK LIQUIDEZ VIVIENDA VALORA'},
				{ id: 24, name: 'SCOTIABANK LIQUIDEZ VIVIENDA PAGOS OPORTUNOS'},
				{ id: 109, name: 'SCOTIABANK LIQUIDEZ LIBRE 7X5'},
				{ id: 144, name: 'SCOTIABANK LIQUIDEZ LIBRE PAGOS OPORTUNO'},
				{ id: 96, name: 'SCOTIABANK LIQUIDEZ LIBRE VALORA'},

				//Preventa
				{ id: 108, name: 'SCOTIABANK PREVENTA HIPOTECA 7X5'},
				{ id: 94, name: 'SCOTIABANK PREVENTA LIBRE PAGOS OPORTUNOS'},
				{ id: 145, name: 'SCOTIABANK PREVENTA LIBRE VALORA'},				

			]

			for (const solicitud of solicitudes) {

				const varianteId = solicitud.VarianteId
				const name = solicitud.Variante

				const isPermitido = permitidos.some(element => element.id === varianteId)
				this.varianteId = varianteId

				if ( isPermitido ) return { isPermitido, name }

			}

			return { isPermitido: false, name: null }

		},
		dameCaso() {
			let self = this
			var objRequestDameCaso = {
				strApiKey: this.key,
				strMetodo: 'DameCaso',
				objCaso: {
					Id: this.id,
				},
			}
			this.$axios
				.post('/', objRequestDameCaso, { headers: { 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' } })
				.then((response) => {
					if (response.data.intEstatus == 0) {
						const cotizacion = response.data.objContenido

						this.emailEjecutivo = cotizacion.Broker
						this.emailSolicitante = cotizacion.Solicitante

						if (response.data.objContenido.ScotiabankPersonales == 'Completo') {
							this.PersonalesCompleto = 'Completo'
						}
						if (response.data.objContenido.ScotiabankCoacreditado == 'Completo') {
							this.CoacreditadoCompleto = 'Datos completos'
						}
						if (response.data.objContenido.ScotiabankReferencias == 'Completo') {
							this.ReferenciasCompleto = 'Datos completos'
						}

						this.solicitudes = cotizacion.Solicitudes

						const isScotia = this.solicitudes.some(banco => banco.IdBanco === 11)

						if ( !isScotia ) return

						const { isPermitido, name } = this.isDestinoPermitido(cotizacion.Solicitudes)

						// console.log(cotizacion.Solicitudes)

						// const isAdquisicionVivienda = cotizacion.Destino == 1 && true
						// const isHipotecaVivienda = cotizacion.Destino == 19 && true

						this.solicidadoBancosSolicitudesScotia = isPermitido ? 'MuestraForm' : true
						this.nameProducto = isPermitido && name

						this.DameExternaAntiguedad()

					} else {
						this.$vs.notify({
							title: 'Ocurrio un error en DameCaso 1',
							text: response.data.strError,
							color: 'danger',
							position: 'top-right',
						})
					}
				})
				.catch(function (error) {
					self.$vs.notify({
						title: 'Ocurrio un error de sistema',
						text: error,
						color: 'danger',
						position: 'top-right',
					})
				})
		},
		DameExternaAntiguedad() {
			let self = this
			const objRequestCount = {
				strFolioCaso: this.id,
				strApiKey: this.key,
				strMetodo: 'PrecaExternaAntiguedad',
				objAntiguedad: {
					EmailEjecutivo: this.emailEjecutivo,
					EmailSolicitante: this.emailSolicitante,
					IdCaso: this.id,
				},
			}

			this.$axios
				.post('/', objRequestCount, { headers: { 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' } })
				.then((response) => {

					const status = response.data.intEstatus
					const resp = response.data.objContenido

					if ( status ) {

						this.$vs.notify({
							title: 'Ocurrio un error en DameExternaAntiguedad',
							text: response.data.strError,
							color: 'danger',
							position: 'top-right',
						})

					}

					if ( resp ) {

						const { IdSessionEjecutivo, Broker, Ano, Mes, Dia } = resp[0]

						if ( IdSessionEjecutivo === Broker ) {

							this.externaBrokerSolicitante = true

						}
						else {

							let MesRegistro = Mes

							let TextMes = ''
							if (MesRegistro == 1) {
								TextMes = 'Enero'
							} else if (MesRegistro == 2) {
								TextMes = 'Febrero'
							} else if (MesRegistro == 3) {
								TextMes = 'Marzo'
							} else if (MesRegistro == 4) {
								TextMes = 'Abril'
							} else if (MesRegistro == 5) {
								TextMes = 'Mayo'
							} else if (MesRegistro == 6) {
								TextMes = 'Junio'
							} else if (MesRegistro == 7) {
								TextMes = 'Julio'
							} else if (MesRegistro == 8) {
								TextMes = 'Agosto'
							} else if (MesRegistro == 9) {
								TextMes = 'Septiembre'
							} else if (MesRegistro == 10) {
								TextMes = 'Octubre'
							} else if (MesRegistro == 11) {
								TextMes = 'Noviembre'
							} else if (MesRegistro == 12) {
								TextMes = 'Diciembre'
							}
	
							this.fechaRegistroExterna = Dia + ' ' + TextMes + ' ' + Ano
							this.externaSolicitante = true

						}

					}

				})
				.catch(function (error) {
					self.$vs.notify({
						title: 'Ocurrio un error de sistema',
						text: error,
						color: 'danger',
						position: 'top-right',
					})
				})

		},
	},
}
</script>

<style lang="scss">
.tooltip-image{
  width: 52px;
  align-content: center;
  display: flex;
}
#profile-tabs {
  .vs-tabs--content {
    padding: 0;
  }
}
.input-group {
    position: relative;
    display: flex;
    flex-wrap: nowrap;
    align-items: stretch;
    width: 100%;
}
button.vs-component.vs-button.vs-button-primary.vs-button-flat {
    padding: 0px !important;
    border-radius: 3px;
}

</style>
